import { render, staticRenderFns } from "./mypage.vue?vue&type=template&id=8d69e8a2&lang=pug"
import script from "./mypage.vue?vue&type=script&lang=js"
export * from "./mypage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonPageHeader: require('/codebuild/output/src675633959/src/codestar-connections.ap-northeast-1.amazonaws.com/git-http/782650252075/ap-northeast-1/24ed63f2-b703-4617-8b62-68a84391d728/nextstage-group/ie-vc-web/src/components/atoms/common/CommonPageHeader.vue').default})
