import { render, staticRenderFns } from "./UserNotificationContainer.vue?vue&type=template&id=301bac35&scoped=true&lang=pug"
import script from "./UserNotificationContainer.vue?vue&type=script&lang=js"
export * from "./UserNotificationContainer.vue?vue&type=script&lang=js"
import style0 from "./UserNotificationContainer.vue?vue&type=style&index=0&id=301bac35&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "301bac35",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonIcon: require('/codebuild/output/src675633959/src/codestar-connections.ap-northeast-1.amazonaws.com/git-http/782650252075/ap-northeast-1/24ed63f2-b703-4617-8b62-68a84391d728/nextstage-group/ie-vc-web/src/components/atoms/common/CommonIcon.vue').default,CommonTextControl: require('/codebuild/output/src675633959/src/codestar-connections.ap-northeast-1.amazonaws.com/git-http/782650252075/ap-northeast-1/24ed63f2-b703-4617-8b62-68a84391d728/nextstage-group/ie-vc-web/src/components/atoms/common/CommonTextControl.vue').default,CommonIconButton: require('/codebuild/output/src675633959/src/codestar-connections.ap-northeast-1.amazonaws.com/git-http/782650252075/ap-northeast-1/24ed63f2-b703-4617-8b62-68a84391d728/nextstage-group/ie-vc-web/src/components/atoms/common/CommonIconButton.vue').default,CommonDataControlButton: require('/codebuild/output/src675633959/src/codestar-connections.ap-northeast-1.amazonaws.com/git-http/782650252075/ap-northeast-1/24ed63f2-b703-4617-8b62-68a84391d728/nextstage-group/ie-vc-web/src/components/atoms/common/CommonDataControlButton.vue').default})
