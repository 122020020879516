/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cognito_identity_pool_id": "ap-northeast-1:8d9d1692-1ca1-4d27-8fc2-7e069769a5b9",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_UwgMAZnQN",
    "aws_user_pools_web_client_id": "55vfp476reibto0qek2v7oed4l",
    "oauth": {},
    baseURL: "https://7imp65te6a.execute-api.ap-northeast-1.amazonaws.com",
    "aws_cloud_logic_custom": [
        {
            "name": "service",
            "endpoint": "https://7imp65te6a.execute-api.ap-northeast-1.amazonaws.com",
            "region": "ap-northeast-1"
        }
    ]
};


export default awsmobile;
